import {
  PAGE_TYPE_DEFAULT_PLP,
  PAGE_TYPE_LENSES_CARE_PLP,
  PAGE_TYPE_LENSES_PLP,
} from '~/constants/pageType'
import type {
  SbGlobalBanner,
  SbNewsletter,
  SbOverlayData,
} from '~/storyblok/types/storyblok'

export const useOverlays = <
  T extends 'banner' | 'newsletter' = 'banner',
  O extends SbGlobalBanner | SbNewsletter = T extends 'banner'
    ? SbGlobalBanner
    : SbNewsletter,
>(
  type: T,
) => {
  const { pageState } = usePageState()
  const pageType = computed(() => pageState.value.pageType)
  const plpType = computed(() => pageState.value.plpType)
  const productCategory = computed(() => pageState.value.productCategory)

  const previewId =
    import.meta.client &&
    new URLSearchParams(window.location.search).get('_storyblok')

  const cms = useCmsStories({
    params: {
      folder: type,
      baseFolder: CmsBaseFolder.Overlays,
      ignoreNotFound: true,
    },
    key: `use-${type}`,
  })

  const { data, fetch, fetching } = cms

  /**
   * Get all active overlays, filter ones which apply to current page and order
   * them by specificity
   */
  const overlays: Ref<SbOverlayData<O>[]> = computed(() => {
    const currentPageType = plpType.value || pageType.value
    if (currentPageType === 'checkout') {
      return []
    }
    return (data.value || [])
      .map((overlay: SbOverlayData<O>) => {
        if (previewId) {
          // while previewing in storyblok we always want to only show the
          // overlay currently being edited and ignore all others
          return overlay.id.toString() === previewId && { ...overlay, order: 1 }
        }
        if (!overlay.content.is_active) {
          return false
        }
        const pageTypes = overlay.content.page_type.flatMap((pt) =>
          `${pt}`.split(','),
        )

        // storyblok still has `home` as an option but `homepage` is used in the app for tracking etc.
        // add homepage to pagetypes to be backwards comnpatible
        if (pageTypes.includes('home')) {
          pageTypes.push('homepage')
        }
        const isGlobal = pageTypes.includes('global')
        const productTypes = overlay.content.product_type
        const pageTypeMatch =
          pageTypes.length && pageTypes.includes(currentPageType)
        const productTypeMatch =
          productTypes?.length && productTypes.includes(productCategory.value)

        // only match with product type if current page type supports it
        const validWithProductType = [
          PAGE_TYPE_DEFAULT_PLP,
          PAGE_TYPE_LENSES_PLP,
          PAGE_TYPE_LENSES_CARE_PLP,
          'product',
        ].includes(currentPageType)

        if (productTypes?.length && validWithProductType) {
          return (
            productTypeMatch &&
            pageTypeMatch && {
              ...overlay,
              order: 1,
            }
          )
        }

        if (pageTypeMatch) {
          return { ...overlay, order: 2 }
        }

        if (isGlobal) {
          return { ...overlay, order: 3 }
        }
        return false
      })
      .filter(Boolean)
      .sort(
        (a: ObjectWith<{ order: number }>, b: ObjectWith<{ order: number }>) =>
          a.order - b.order,
      )
  })

  return extendPromise(
    cms.then(() => ({})),
    {
      data: overlays,
      fetching,
      fetch,
    },
  )
}
