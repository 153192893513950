<template>
  <div
    class="anchor-scrolling-none flex min-h-screen flex-col text-primary antialiased"
  >
    <div class="overflow-x-hidden">
      <ClientOnly>
        <aside v-if="!isStoreMode">
          <template v-for="(banner, i) in banners" :key="`banner-${i}`">
            <LazyBanner
              v-if="
                isPreview ||
                shouldBeVisible(
                  {
                    ...banner.content,
                    published_at: banner.published_at!,
                  },
                  banner.uuid,
                )
              "
              :blok="{
                ...banner.content,
                published_at: banner.published_at!,
              }"
              :uuid="banner.uuid"
            />
          </template>
        </aside>
      </ClientOnly>
    </div>
    <AppHeader
      v-if="!isStoreMode"
      v-bind="{
        fetching: fetchingNavigationTree,
        navigationTree: navigationTree || [],
      }"
    />
    <LazyToastContainer v-if="$alert.alerts.value.length" />
    <LazyMobileNavigation
      v-if="isLessThan('header')"
      :fetching="fetchingNavigationTree"
      :navigation-tree="navigationTree || []"
    />
    <main
      :class="{ 'min-h-[80vh]': !isAccountArea, 'min-h-[40vh]': isAccountArea }"
    >
      <NuxtPage data-testid="home-page-content" />
    </main>

    <Footer v-if="!isStoreMode" v-once />

    <ClientOnly>
      <template v-if="newsletter && !isStoreMode">
        <Overlay
          portal-target="#newsletter-overlay"
          title="Newsletter"
          :open="isNewsletterVisible"
          :no-padding="true"
          :show-title="false"
          :mobile-windowed="true"
          @update:open="closeNewsletter"
        >
          <Newsletter
            :blok="newsletter.content"
            :uuid="newsletter.uuid"
            layout="vertical"
            @update:open="closeNewsletter"
          />
        </Overlay>
      </template>
    </ClientOnly>

    <ClientOnly>
      <RopoOverlay v-if="!isStoreMode" />
    </ClientOnly>

    <ClientOnly>
      <LanguageSwitcherOverlay v-if="!isStoreMode" />
    </ClientOnly>

    <div id="ropo-overlay"></div>
    <div id="product-detail-size-portal" data-testid="sizes-overlay"></div>
    <div id="rx-find-portal"></div>
    <div id="rx-sight-prescription-portal"></div>
    <div id="rx-pd-tool-portal"></div>
    <div id="rx-lens-quality-portal"></div>
    <div id="rx-extras-portal"></div>
    <div id="newsletter-overlay"></div>
    <div id="language-overlay"></div>
    <div id="info-overlay" data-testid="info-overlay"></div>
    <div id="fitmix-overlay"></div>
    <div id="modal-overlay"></div>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, hydrateOnVisible } from 'vue'
import fontBold from '~/assets/fonts/FielmannSans-Bold.woff2?url'
import fontBoldCondensed from '~/assets/fonts/FielmannSans-BoldCondensed.woff2?url'
import fontMedium from '~/assets/fonts/FielmannSans-Medium.woff2?url'
import fontRegular from '~/assets/fonts/FielmannSans-Regular.woff2?url'
import useUiState from '~/composables/ui/useUiState'
import { useFeatureToggles } from '~/composables/useFeatureToggles'

if (import.meta.server) {
  useHead({
    link: [fontRegular, fontMedium, fontBold, fontBoldCondensed].map(
      (href) => ({
        rel: 'preload',
        href,
        crossorigin: '',
        as: 'font',
        type: 'font/woff2',
      }),
    ),
    script: [
      {
        type: 'speculationrules',
        children: JSON.stringify({
          prefetch: [
            {
              where: {
                selector_matches: '[data-prefetch]',
              },
              eagerness: 'eager',
            },
            {
              where: {
                and: [
                  { href_matches: '/*' },
                  { not: { href_matches: '/logout' } },
                  { not: { href_matches: '/signin' } },
                  { not: { selector_matches: '.no-prefetch' } },
                ],
              },
              eagerness: 'moderate',
            },
          ],
          prerender: [
            {
              where: {
                selector_matches: '[data-prefetch]',
              },
              eagerness: 'moderate',
            },
          ],
        }),
      },
    ],
  })
}

const Footer = defineAsyncComponent({
  loader: () => import('~/components/layout/footer/AppFooter.vue'),
  hydrate: hydrateOnVisible(),
})

const runtimeConfig = useRuntimeConfig()

const { isLessThan } = useViewport()
const {
  isDialogOpen,
  isFitMixOpen,
  isSearchFlyoutOpen,
  isOverlayOpen,
  isRxConfiguratorOverlayOpen,
  openLanguageSwitcherOverlay,
} = useUiState()

const { isStoreMode } = useFeatureToggles()

const route = useRoute()
const switchLocalePath = useSwitchLocalePath()
const currentShop = useCurrentShop()
const { configuredLocale, isMultilanguageShop, hasNoLanguagePreference } =
  useLocale()
const getRouteBaseName = useRouteBaseName()
// Need to make sure to have all RX product options so price can be
// calculated in useProductPrice
useRxProducts()

const scope = effectScope()
onScopeDispose(() => scope.stop())

const isPreview = Boolean(getQueryParam(route?.query, '_storyblok'))

/**
 * Meta
 */
const { isSideNavigationOpen } = useSideNavigation()
const { isOpen: isModalOpen } = useModal()
const isAccountArea = computed(() => route?.path?.includes('account'))

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
})

useHead({
  htmlAttrs: {
    ...i18nHead.value.htmlAttrs,
  },

  bodyAttrs: () => ({
    class: [
      'relative',
      isSideNavigationOpen.value || isModalOpen.value
        ? 'overflow-hidden h-full'
        : '',
      isSideNavigationOpen.value ||
      isModalOpen.value ||
      isFitMixOpen.value ||
      isDialogOpen.value ||
      isSearchFlyoutOpen.value ||
      isOverlayOpen.value ||
      isRxConfiguratorOverlayOpen.value
        ? 'overscroll-none'
        : '',
      isFitMixOpen.value ||
      isOverlayOpen.value ||
      isRxConfiguratorOverlayOpen.value
        ? 'overflow-hidden'
        : '',
    ],
  }),
})

scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch(
    configuredLocale,
    async (newLang) => {
      if (
        !isMultilanguageShop.value ||
        !newLang ||
        currentShop.value.locale === newLang
      ) {
        return
      }
      const trueRouteName = getRouteBaseName(route)
      const routeMatch = Object.entries(routeList).some(
        ([_, route]) => route.name === trueRouteName,
      )
      if (!routeMatch) {
        return
      }

      const url = switchLocalePath(newLang.toLowerCase())
      await navigateTo(url, { redirectCode: 302, external: true })
    },
    { immediate: true },
  )
})

if (isMultilanguageShop.value && hasNoLanguagePreference.value) {
  openLanguageSwitcherOverlay()
}

const { data: navigationTree, pending: fetchingNavigationTree } =
  useFimNavigationTree()

const { banners, shouldBeVisible } = useBanners()

const {
  newsletter,
  fetch: fetchNewsletters,
  isVisible: isNewsletterVisible,
  close: closeNewsletter,
} = useNewsletters()

useWishlist()
useBasket()

onMounted(() => fetchNewsletters())

/**
 * @todo tracking
 */

// const { trackShopInit, listenToUserItemsChanges, listenToCustomerDataChanges } =
//   await useTrackingEvents()
// trackShopInit()
// await listenToUserItemsChanges()
// await listenToCustomerDataChanges()

/**
 * Error handling
 */
onErrorCaptured((err, target, info) => {
  throw createError({
    statusCode: 500,
    statusMessage: err.message,
    ...err,
    fatal: true,
  })
})

onMounted(() => {
  if (runtimeConfig.public.appEnv !== 'production') {
    debug('currentShop', currentShop.value)
    debug('runtimeConfig', runtimeConfig)
  }
})

defineOptions({ name: 'AppDefault' })
</script>
