<template>
  <Overlay
    :title="$t('ropo.add_to_basket.overline', { product: ropoProductName })"
    :open="isRopoOverlayOpen"
    portal-target="#ropo-overlay"
    data-testid="ropo-overlay"
    @update:open="handleClose"
  >
    <h2 class="mb-4 text-[24px] font-medium leading-[32px]">
      {{ $t('ropo.add_to_basket.headline') }}
    </h2>
    <div
      v-dompurify-html="$t('ropo.add_to_basket.text')"
      class="overlay space-y-4 text-lg leading-6"
    ></div>
    <div class="mt-8 border-t border-[#D6D6D6] pt-6 md:flex md:justify-between">
      <FimButton
        no-padding
        data-testid="ropo-overlay-cancel"
        type="link-alternative"
        class="hidden md:inline-block"
        @click="handleClose"
      >
        <span class="font-normal">
          {{ $t('ropo.add_to_basket.cancel') }}
        </span>
      </FimButton>

      <FimButton
        no-padding
        data-testid="ropo-overlay-goto-basket"
        type="primary"
        class="my-2 w-full px-6 py-3 text-lg md:mb-0 md:max-w-xs"
        @click="handleLink"
      >
        <span class="font-normal">
          {{ $t('ropo.add_to_basket.goTo') }}
        </span>
      </FimButton>

      <FimButton
        no-padding
        data-testid="ropo-overlay-cancel"
        type="secondary"
        class="mt-2 w-full px-6 py-3 text-lg md:hidden"
        @click="handleClose"
      >
        <span class="font-normal">
          {{ $t('ropo.add_to_basket.cancel') }}
        </span>
      </FimButton>
    </div>
  </Overlay>
</template>
<script setup lang="ts">
import useUiState from '~/composables/ui/useUiState'

const localePath = useFimLocalePath()
const router = useRouter()
const { isRopoOverlayOpen, closeRopoOverlay, ropoProductName } = useUiState()
const handleClose = (_e: Event) => {
  closeRopoOverlay()
}
const handleLink = (_e: Event) => {
  closeRopoOverlay()
  router.push(localePath(routeList.ropoBasket))
}
</script>
